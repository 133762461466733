import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";
import {
  getUserNuuid,
  parseUserNotifications,
} from "../../notifications/ws-messaging";

const initialState = {
  notifications: [],
};

const getCustomHeaders = () => ({
  "X-APP-KEY": process.env.VUE_APP_PUSHER_APP_KEY,
  "X-USER-UUID": getUserNuuid(), // TODO: Get the id here?
});

const _refreshUserNotifications = async (commit) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restClient(
        path.client.messaging.notifications.base,
        "GET",
        null,
        null,
        getCustomHeaders()
      );
      commit(actions.GET_USER_NOTIFICATIONS, response);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export default {
  namespaced: true,
  name: "notifications",
  state: { ...initialState },
  mutations: {
    [actions.GET_USER_NOTIFICATIONS](state, payload) {
      state.notifications = parseUserNotifications(payload?.data?.data || []);
    },
    [actions.RESET_USER_NOTIFICATIONS](state) {
      state.notifications = [];
    },
  },
  getters: {
    getUserNotifications: (state) => state.notifications,
  },
  actions: {
    async refreshUserNotifications({ commit }) {
      return _refreshUserNotifications(commit);
    },
    async updateUserNotifications({ commit }, payload) {
      await restClient(
        path.client.messaging.notifications.single(payload.uuid),
        "POST",
        payload,
        null,
        getCustomHeaders()
      );
      _refreshUserNotifications(commit);
    },
    async deleteUserNotifications({ commit }, payload) {
      await restClient(
        path.client.messaging.notifications.single(payload.uuid),
        "DELETE",
        null,
        null,
        getCustomHeaders()
      );
      _refreshUserNotifications(commit);
    },
    async resetNotifications({ commit }) {
      commit(actions.RESET_USER_NOTIFICATIONS);
    },
  },
};
