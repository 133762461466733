import _ from "lodash";
import { path } from "../../api";
import { restClient, restClientAll } from "../../utils/restClient";
import router from "../../router";

const initialState = {};

export default {
  namespaced: true,
  name: "shortUrl",
  state: _.cloneDeep(initialState),
  actions: {
    async getShortUrl(_, payload = null) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientAll(
            path.client.shortUrl.single(payload),
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async generateShortUrl(_, payload = null) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.paywallLink,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async generateShortUrlMoneygrator(_, payload = null) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.moneygrator,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchShortUrl(_, payload) {
      try {
        const { data: resData } = await restClientAll(
          path.client.shortUrl.single(payload),
          "GET"
        );
        const data = JSON.parse(window.atob(resData.url));

        // redirect to register with referral code
        if (data.shared_by) {
          router.push({
            name: "register",
            query: { referralId: data.shared_by },
          });
        } else {
          router.push({
            name: "register",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
