import { echoConfig } from "./config";
import store from "../store";
import localStorageService from "../utils/localStorage";
import { apiLsClientVariable } from "../utils/utils";

const Pusher = require("pusher-js");

window.$pusher = new Pusher(echoConfig.key, echoConfig);

export const subscribeToChannel = (channel, event, callback) => {
  const subscription = window.$pusher.subscribe(channel);
  subscription.bind(event, callback);
  return subscription;
};

export const unsubscribeFromChannel = (subscription) => {
  subscription.unbind();
  subscription.unsubscribe();
};

export const subscribeToUserChannel = async (toast, nuuid) => {
  getUserNuuid(nuuid);
  refreshUserNotifications();
  const test = subscribeToChannel(
    // `private-user.${nuuid}`,
    `user.${nuuid}`,
    "App\\Events\\UserEvent",
    async (message) => {
      if (toast && message.message?.title) toast.success(message.message.title);
      refreshUserNotifications();
    }
  );

  return test;
};

export const subscribeToGlobalChannel = async (toast) => {
  return subscribeToChannel(
    "global-channel",
    "App\\Events\\GlobalEvent",
    async (message) => {
      if (toast && message.message?.title) toast.success(message.message.title);
      refreshUserNotifications();
    }
  );
};

export const parseUserNotifications = (notifications) => {
  return notifications.map((notification) => {
    // TODO: Remove unnecessary/sensitive fields ?
    const data = notification.data;
    return {
      ...notification,
      ...data,
    };
  });
};

export const getUserNuuid = (nuuid) => {
  const key = apiLsClientVariable + "-NUUID";
  if (nuuid) {
    localStorageService.set(key, window.btoa(nuuid));
  } else {
    nuuid = window.atob(localStorageService.get(key));
  }
  return nuuid;
};

export const refreshUserNotifications = () => {
  store.dispatch("notifications/refreshUserNotifications");
};

export const updateUserNotification = async (notification) => {
  store.dispatch("notifications/updateUserNotifications", notification);
};

export const deleteUserNotification = async (notification) => {
  store.dispatch("notifications/deleteUserNotifications", notification);
};
