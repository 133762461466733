import _ from "lodash";
import * as Sentry from "@sentry/vue";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import router from "../../router";
import {
  restClient,
  restClientWithOutAuthentication,
} from "../../utils/restClient";
import { apiLsClientVariable, getUserData } from "../../utils/utils";
import localStorageService from "../../utils/localStorage";
import { subscribeToUserChannel } from "@/notifications/ws-messaging.js";

const initialState = {
  authenticated: false,
  user: null,
  token: "",
  expires_in: 0,
  fromRegister: false,
};

export default {
  namespaced: true,
  name: "clientAuth",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.CLIENT_LOGIN](state, payload = _.cloneDeep(initialState)) {
      Object.assign(state, payload);
    },
    [actions.CLIENT_LOGOUT](state) {
      Object.assign(state, _.cloneDeep(initialState));
    },
    [actions.CLIENT_SET_IS_FROM_REGISTER](state, payload = false) {
      state.fromRegister = payload;
    },
    [actions.CLIENT_BALANCE](state, payload = 0) {
      state.user.balance = payload;
    },
  },
  actions: {
    async handleRegister({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.register,
            "POST",
            payload
          );

          // NOTE: HANDLE LOGIN IF TOKEN IS RETURNED
          const { token, expires_in } = response;
          if (token) {
            // SAVE TOKEN TO SESSION STORAGE
            localStorageService.set(
              apiLsClientVariable,
              window.btoa(JSON.stringify({ token, expires_in }))
            );

            const user = getUserData(token);

            subscribeToUserChannel(this.$message, user.nuuid);

            commit(actions.CLIENT_LOGIN, {
              user,
              token,
              expires_in,
              authenticated: true,
            });

            resolve(user);
          } else {
            resolve(response);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async handleLogin({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { token, expires_in } = await restClientWithOutAuthentication(
            path.client.login,
            "POST",
            payload,
            {},
            { "Content-Type": "multipart/form-data" }
          );

          // SAVE TOKEN TO SESSION STORAGE
          localStorageService.set(
            apiLsClientVariable,
            window.btoa(JSON.stringify({ token, expires_in }))
          );

          const user = getUserData(token);

          subscribeToUserChannel(this.$message, user.nuuid);

          commit(actions.CLIENT_LOGIN, {
            user,
            token,
            expires_in,
            authenticated: true,
          });
          resolve(user);
        } catch (error) {
          if (
            error.isAxiosError &&
            ![400, 401, 403].includes(error.response.status)
          ) {
            try {
              Sentry.captureException(error, {
                tags: {
                  module: "clientAuth",
                  action: "handleLogin",
                },
                extra: {
                  response: error.response,
                  request: error.request,
                  payload,
                },
              });
            } catch (error) {
              console.error(error);
            }
          }
          commit(actions.CLIENT_LOGIN);
          reject(error);
        }
      });
    },
    async checkSession({ commit }) {
      try {
        const session = JSON.parse(
          window.atob(localStorageService.get(apiLsClientVariable)) || null
        );
        if (session) {
          const userData = await getUserData(session.token);
          if (userData.module === "user") {
            const route = path.client.loggedUser;
            const { data: user } = await restClient(route, "POST");
            commit(actions.CLIENT_LOGIN, {
              user,
              token: session.token,
              expires_in: session.expires_in,
              authenticated: true,
            });
            // Redicrect to home page
            if (
              ["login", "register", "password-reset"].includes(
                router.currentRoute.name
              )
            ) {
              router.push({
                name: "home",
              });
            }
          } else {
            commit(actions.CLIENT_LOGIN, null);
            localStorageService.remove(apiLsClientVariable);
            localStorageService.remove(apiLsClientVariable + "-NUUID");
          }
        } else {
          commit(actions.CLIENT_LOGIN, null);
          localStorageService.remove(apiLsClientVariable);
          localStorageService.remove(apiLsClientVariable + "-NUUID");
        }
      } catch (error) {
        if (error.isAxiosError && [401, 403].includes(error.response.status)) {
          commit(actions.CLIENT_LOGIN, null);
          localStorageService.remove(apiLsClientVariable);
          localStorageService.remove(apiLsClientVariable + "-NUUID");
        }
      }
    },
    async handleLogout({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        try {
          await restClient(path.client.logout, "POST");
        } catch (error) {
          console.error(error, "error");
        } finally {
          await localStorageService.remove(apiLsClientVariable);
          await localStorageService.remove(apiLsClientVariable + "-NUUID");
          commit(actions.CLIENT_LOGOUT);
          resolve();
        }
      });
    },
    async handleRequestPasswordReset(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClientWithOutAuthentication(
            path.client.requestPasswordReset,
            "POST",
            payload
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handleVerifyAccount(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.verifyAccount(payload),
            "POST"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handlePasswordResetCodeVerify(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.verifyPasswordResetCode(payload),
            "POST"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handlePasswordReset(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.passwordReset,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handleChangePassword(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.changePassword,
            "PUT",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handleJoinMailList(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.mailList.join,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handleLeaveMailList(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.mailList.leave,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getAllMyReferrals(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.myReferrals,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchCommissionProfile() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.commissionProfile,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async setFromRegister({ commit }, payload = false) {
      commit(actions.CLIENT_SET_IS_FROM_REGISTER, payload);
    },
    async refreshBalance({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(path.client.balance, "GET");
          commit(actions.CLIENT_BALANCE, response.data.balance);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchCommissionsPending() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.commissionsPending,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handleVerifyPhone(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.verifyPhone,
            payload ? "POST" : "GET",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated;
    },
    isInBlackList(state) {
      return state.user?.blcc || false;
    },
    isCreditCardEnabled(state) {
      return state.user?.icce || false;
    },
    getLoggedUser: (state) => {
      return state.user;
    },
    isFromRegister(state) {
      return state.fromRegister;
    },
    getUserNuuid(state) {
      return state.user?.nuuid;
    },
  },
};
