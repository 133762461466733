import { hasRoles, roles as systemRoles } from "../utils/utils";

/* eslint-disable */
export const menus = (roles = [],addMenus = []) =>  [...addMenus,...[
    {
        key: 'home',
        title: 'Inicio',
        canEnter: true,
        icon: ['fa', 'home'],
        isFontAwesome: true,
    },
    {
        key: 'results',
        title: 'Resultados',
        canEnter: true,
        icon: ['fas', 'poll'],
        isFontAwesome: true,
    },
    {
        key: 'how-to-play',
        title: '¿Como Jugar?',
        canEnter: true,
        icon: ['fas', 'ticket-alt'],
        isFontAwesome: true,
    },
    {
        key: 'contact',
        title: 'Contacto',
        canEnter: true,
        icon: ['fas', 'envelope'],
        isFontAwesome: true,
    },
    {
        key: 'login',
        title: 'Acceso',
        canEnter: true,
        icon: ['fas', 'sign-in-alt'],
        isFontAwesome: true,
    },
    {
        key: 'register',
        title: 'Registrarse',
        canEnter: true,
        icon: ['fas', 'user-plus'],
        isFontAwesome: true,
    },
    {
        key: 'welcome',
        title: 'Bienvenido',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        icon: ['fas', 'user'],
        isFontAwesome: true,
        hiddenHeader: true,
    },
    {
        key: 'buy',
        title: '!Juega Ahora!',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hiddenHeader: true,
    },
    {
        key: 'password-reset',
        title: 'Restablecer contraseña',
        canEnter: true,
        icon: ['fas', 'user-lock'],
        isFontAwesome: true,
        hidden: true,
    },
    {
        key: 'verify-account',
        title: 'Verificación de cuenta',
        canEnter: true,
        icon: ['fas', 'user-check'],
        isFontAwesome: true,
        hidden: true,
    },
    {
        key: 'password-reset-verification',
        title: 'Cambio de contraseña',
        canEnter: true,
        icon: ['fas', 'user-lock'],
        isFontAwesome: true,
        hidden: true,
    },
    {
        key: 'change-password',
        title: 'Cambiar Contraseña',
        canEnter: true,
        icon: ['fas', 'user-lock'],
        isFontAwesome: true,
        hidden: true,
    },
    {
        key: 'referrals',
        title: 'Referidos',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hidden: true,
    },
    {
        key: 'ticket-list',
        title: 'Listado de jugadas',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hidden: true,
    },
    {
        key: 'ticket-view',
        title: 'Jugada',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hidden: true,
    },
    {
        key: 'transaction-list',
        title: 'Transacciones',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hidden: true,
    },
    {
        key: 'transaction-view',
        title: 'Transacción',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hidden: true,
    },
    {
        key: 'transaction-request',
        title: 'Solicitud de transacción',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hidden: true,
    },
    {
        key: 'balance-movements',
        title: 'Movimientos de saldo',
        canEnter: hasRoles(roles, [systemRoles.client.ROLE_BUYER, systemRoles.client.ROLE_SELLER]),
        hidden: true,
    },
    {
        key: 'privacy-policies',
        title: 'Politicas de privacidad',
        canEnter: true,
        hidden: true,
    },
    {
        key: 'terms-and-conditions',
        title: 'Terminos y condiciones',
        canEnter: true,
        hidden: true,
    },
    {
        key: 'notifications',
        title: 'Notificaciones',
        canEnter: true,
        hidden: true,
    },
]]
.filter(menu => menu.canEnter)
.filter((item = {}) => (item.hasOwnProperty('children') && item.children.length > 0) || !item.hasOwnProperty('children'))
;