import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  users: [],
  user: null,
  bankAccounts: [],
};

export default {
  namespaced: true,
  name: "users",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_USER](state, payload = null) {
      state.user = payload;
    },
    [actions.GET_USERS](state, payload = []) {
      state.users = payload;
    },
    [actions.CREATE_USER](state, payload = null) {
      state.user = { ...payload, password: "" };
    },
    [actions.UPDATE_USER](state, payload = null) {
      state.user = { ...payload, password: "" };
    },
    [actions.DELETE_USER](state, payload = null) {
      state.user = payload;
    },
    [actions.RESET_USER_FORM](state) {
      state.user = null;
    },
    [actions.GET_USER_BANK_ACCOUNTS](state, payload = []) {
      state.bankAccounts = payload;
    },
  },
  actions: {
    async fetchUsers({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.users.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_USERS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_USERS, []);
          reject(error);
        }
      });
    },
    async fetchUserBalanceMovements(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.balanceMovements.base,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchUser({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.users.single(payload),
            "GET"
          );
          commit(actions.GET_USER, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_USER);
          reject(error);
        }
      });
    },
    async createUser({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.users.base,
            "POST",
            payload
          );
          commit(actions.CREATE_USER, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateUser({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.users.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_USER, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteUser({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.users.single(payload),
            "DELETE"
          );
          commit(actions.DELETE_USER);
          resolve(response);
        } catch (error) {
          commit(actions.DELETE_USER);
          reject(error);
        }
      });
    },
    async fetchClientUserBalanceMovements(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.balanceMovements.base,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getUserBankAccounts({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.bankAccounts.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_USER_BANK_ACCOUNTS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_USER_BANK_ACCOUNTS, []);
          reject(error);
        }
      });
    },
    async updateUserBankAccount(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.bankAccounts.single(payload.id),
            "PUT",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteUserBankAccount(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.bankAccounts.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async createUserBankAccount(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.bankAccounts.single(payload),
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_USER_FORM);
    },
  },
};
