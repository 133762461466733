export const echoConfig = {
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  wsHost: process.env.VUE_APP_PUSHER_WS_HOST,
  wsPort: process.env.VUE_APP_PUSHER_WS_PORT,
  forceTLS: process.env.VUE_APP_PUSHER_FORCE_TLS,
  disableStats: process.env.VUE_APP_PUSHER_DISABLE_STATS,
  // httpHost: process.env.VUE_APP_PUSHER_WS_HOST,
  // authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
  // //encrypted: process.env.VUE_APP_PUSHER_ENCRYPTED,
  // wssPort: process.env.VUE_APP_PUSHER_WSS_PORT,
  // enabledTransports: process.env.VUE_APP_PUSHER_WSS_PORT,
  // cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
};
